
$primary-color: #91a979;
$secondary-color: #93b9c6;
$alert-color: #f3a5a5;
$success-color: #c6e3de;
$background-color: #ede5e3;

body {
  margin: 0;
  font-family: "brandon-grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  h1 {
    font-family: "brandon-grotesque", sans-serif;
    font-weight: 300;
  }
}
